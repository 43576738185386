import { Injectable, OnDestroy } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router'
import { distinctUntilChanged, filter, map, of, pairwise, startWith, Subscription, switchMap } from 'rxjs'
import { environment } from 'src/environments/environment'

declare global {
    interface Window {
        HubSpotConversations:
            | {
                  clear: (options?: { resetWidget: boolean }) => void
                  widget: {
                      load: () => void
                      remove: () => void
                      close: () => void
                      open: () => void
                      refresh: (openToNewThread: boolean) => void
                      status: () => { loaded: boolean }
                  }
                  resetAndReloadWidget: () => void
              }
            | undefined
        hsConversationsSettings:
            | {
                  loadImmediately: boolean
                  identificationEmail?: string
                  identificationToken?: string
              }
            | undefined
        hsConversationsOnReady: (() => void)[] | undefined
    }
}
@Injectable({
    providedIn: 'root',
})
export class HubspotChatService implements OnDestroy {
    private isLoaded = false
    private userIsLogged = false

    private subscriptions = new Subscription()

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        interface ChatRefreshConfig {
            path: RegExp
            queryParamKeys?: string[]
        }

        this.subscriptions.add(
            this.router.events
                .pipe(
                    filter((ev) => ev instanceof NavigationEnd),
                    map((ev) => window.location.pathname),
                    distinctUntilChanged(),
                    startWith('/'),
                    pairwise(),
                    map(([previousPath, currentPath]) => {
                        const pathsRequiringChatsRefresh: ChatRefreshConfig[] = [
                            {
                                path: /\/admin\/mailing\/drafts\/v2\/[A-Za-z0-9]{10}/,
                                queryParamKeys: ['tab'],
                            },
                            {
                                path: /\/admin\/mailing\/previous/,
                            },
                            {
                                path: /\/editor\/[A-Za-z0-9]{10}$/,
                            },
                            {
                                path: /\/login/,
                            },
                            {
                                path: /\/mailing-list\/add-update/,
                            },
                            {
                                path: /\/mailing-list\/purchase/,
                            },
                            {
                                path: /\/mailing-list\/suppression-list/,
                            },
                            {
                                path: /\/products/,
                            },
                            {
                                path: /\/templates/,
                            },
                            {
                                path: /\/templates\/upload-my-own-design/,
                            },
                            {
                                path: /\/tracking/,
                            },
                        ]
                        const match: [ChatRefreshConfig | undefined, ChatRefreshConfig | undefined] = [
                            pathsRequiringChatsRefresh.find((v) => v.path.test(previousPath)),
                            pathsRequiringChatsRefresh.find((v) => v.path.test(currentPath)),
                        ]
                        return match
                    }),
                    map((v) => {
                        return v
                    }),
                    switchMap(([previous, current]) => {
                        if (!current) {
                            // Refresh if previous path had and current doesn't
                            return of(!!previous)
                        }

                        if (!current.queryParamKeys) {
                            return of(true)
                        }

                        const queryParamKeys = current.queryParamKeys

                        return this.route.queryParams.pipe(
                            startWith<Params>({}),
                            pairwise(),
                            map(([previousQueryParams, currentQueryParams]) => {
                                return queryParamKeys.some((queryParamKey) => {
                                    // Refresh if query param key value has changed
                                    return previousQueryParams[queryParamKey] !== currentQueryParams[queryParamKey]
                                })
                            })
                        )
                    })
                )
                .subscribe((v) => {
                    if (v) {
                        this.refreshChat(true)
                    }
                })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    loadChat() {
        if (this.isLoaded) {
            return
        }
        window.hsConversationsSettings = {
            loadImmediately: false,
        }

        const script = document.createElement('script')
        script.src = environment.HUBSPOT_CHAT_URL
        script.id = 'hs-script-loader'
        script.async = true
        document.body.appendChild(script)
        this.isLoaded = true
    }

    resetChat() {
        if (!this.isLoaded) {
            this.loadChat()
        }

        if (window.HubSpotConversations) {
            window.hsConversationsOnReady = []
            window.hsConversationsSettings = { loadImmediately: false }
            window.HubSpotConversations?.resetAndReloadWidget()

            if (!window.HubSpotConversations.widget.status().loaded) {
                window.HubSpotConversations.widget.load()
            }
            this.userIsLogged = false
        } else {
            window.hsConversationsOnReady = [
                () => {
                    this.resetChat()
                },
            ]
        }
    }

    async logUser(email: string, token: string) {
        if (!this.isLoaded) {
            this.loadChat()
        }

        if (window.HubSpotConversations) {
            window.hsConversationsOnReady = []
            window.hsConversationsSettings = {
                loadImmediately: false,
                identificationEmail: email,
                identificationToken: token,
            }
            window.HubSpotConversations.resetAndReloadWidget()
            if (!window.HubSpotConversations.widget.status().loaded) {
                window.HubSpotConversations.widget.load()
            }
            this.userIsLogged = true
        } else {
            window.hsConversationsOnReady = [
                () => {
                    this.logUser(email, token)
                },
            ]
        }
    }

    closeChat() {
        window.HubSpotConversations?.widget.close()
    }

    openChat() {
        window.HubSpotConversations?.widget.open()
    }

    private refreshChat(openToNewThread: boolean) {
        window.HubSpotConversations?.widget.refresh(openToNewThread)
    }
}
